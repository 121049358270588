import { Box } from "@chakra-ui/react";
import { TutorialDialogPropsStep } from "../../../components/TutorialDialog";

export const gradTutorialSteps: TutorialDialogPropsStep[] = [
  {
    title: "勾配降下法とは",
    content: (
      <>
        <Box mb={2}>
          現代の機械学習のもっとも基礎的な技術のひとつに、勾配降下法があります。
          勾配降下法とは、ある問題の解答を求めるとき、現在の解答の位置からより解答に近づける方向を見つけて、その方向に進んでいくことを繰り返す方法です。
        </Box>
        <Box mb={2}>
          山登りを例に考えてみましょう。あなたが山のある地点にいることをイメージしてください。
          もし山の頂上にたどり着きたいなら、どのような方向に進めばよいでしょうか。
          それは、その地点においてもっとも「上り坂」になっているような方向です。
          もっとも上り坂になっているような方向を見つけたら、その方向に少し進み、進んだあとの地点で再びもっとも上り坂になっている方向を探します。
          このことを繰り返せば、いつかは山の頂上に着くことができると考えられます。
        </Box>
        <img
          width="100%"
          src={new URL("../images/mountain1.svg", import.meta.url).toString()}
          alt="mountain2"
        />
      </>
    ),
  },
  {
    title: "上り坂の方向",
    content: (
      <>
        <Box mb={2}>
          さて、もっとも上り坂になっている方向を見つけるにはどうすればよいでしょうか。
          これは、x軸方向とy軸方向それぞれで、現在地点における山の「傾き」を求めればよいです。
        </Box>
        <Box mb={2}>
          x軸方向における傾きとは、x軸方向に少しだけ進んだとき(y軸方向は元の位置のまま)に、山の高さがどれほど変化するかの割合です。
          例えば今の地点の高さが100mだったとします。そしてx軸方向に0.01mだけ進んだとき、山の高さが100.2mになったとしましょう。
          このときx軸方向における傾きは、100.2m-100m=0.2mを0.01mで割って、20と求まります。
          y軸方向における傾きについても同様です。
        </Box>
        <Box mb={2}>
          そして実は、x軸方向における傾きとy軸方向における傾きを合わせた方向(具体的には2つの傾きをそれぞれ表す矢印がなす平行四辺形の対角線の方向)が、
          もっとも上り坂になっている方向になります。
        </Box>
        <Box mb={2}>
          ではこの事実を用いて、山のどんな地点から始めても山頂にたどり着けるようなプログラムを作成しましょう。
        </Box>
        <img
          width="100%"
          src={new URL("../images/mountain2.svg", import.meta.url).toString()}
          alt="mountain2"
        />
      </>
    ),
  },
];
