import { Box } from "@chakra-ui/react";
import { TutorialDialogPropsStep } from "../../../components/TutorialDialog";

export const QlearningTutorialSteps: TutorialDialogPropsStep[] = [
  {
    title: "全体の道筋",
    content: (
      <Box>
        <Box>最初はスタート(左上)の位置にいて、</Box>
        <Box>①上下左右のどの方向に行くかを決め、移動可能であれば移動する。</Box>
        <Box>②報酬を設定する。</Box>
        <Box>③移動した後の、最適な移動方向を求める。</Box>
        <Box>④評価値を更新する。</Box>
        <Box>
          これらをゴールにたどり着くまで繰り返し、ゴールにたどり着いたら再びスタートに戻ります。ゴールにたどり着いたかを判定するには「現在位置がゴールである」ブロックを使い、スタートに戻るには「スタートに移動する」ブロックを使います。
        </Box>
      </Box>
    ),
  },
  {
    title: "評価値ブロックの使い方",
    content: (
      <Box>
        <img
          src={new URL(
            "./2023-05-12-164542_359x128_scrot.png",
            import.meta.url
          ).toString()}
          alt="評価値ブロックの画像"
        />
        <Box>
          １つ目の引数には状態を表す数字が入ります。迷路探索の場合は、状態は現在位置であるので、「現在位置の行」ブロックと「現在位置の列」ブロックを用いて迷路上に書いてある数字と一致するように状態を表してください。(行・列は0から始まります。)
        </Box>
        <img
          src={new URL(
            "./2023-05-12-170718_143x117_scrot.png",
            import.meta.url
          ).toString()}
          alt="現在位置の行・列"
        />
        <Box>
          ２つ目の引数には移動する方向が入ります。方向は、変数に代入して使うことも出来ます。
        </Box>
        <img
          src={new URL(
            "./2023-05-12-171014_362x74_scrot.png",
            import.meta.url
          ).toString()}
          alt="現在位置の行・列"
        />
        <Box>
          評価値を更新するブロックの３つ目の引数には更新したい値を入れます。
        </Box>
      </Box>
    ),
  },
  {
    title: "最適な移動方向の求め方",
    content: (
      <Box>
        <Box>
          まず最初に変数に適当に上下左右のいずれかをセットします。変数は以下の図の「変数の作成...」から作ることが出来ます。
        </Box>
        <img
          src={new URL(
            "./2023-05-12-170345_197x130_scrot.png",
            import.meta.url
          ).toString()}
          alt="変数"
        />
        <Box>
          そして、「もし...ならば」のブロックを使って、左に行く方が変数に入っている方向に移動するよりも評価値が高ければ、変数の値を左に書き換えます。これを、右、上、下についても同様のことをします。
        </Box>
        <Box>
          (0の部分には位置を表す状態(評価値ブロックの使い方を参照)を入れてください)
        </Box>
        <img
          src={new URL(
            "./2023-05-13-133136_815x123_scrot.png",
            import.meta.url
          ).toString()}
          alt="移動方向"
        />
        <Box>これで最適な移動方向を求めることが出来ます。</Box>
      </Box>
    ),
  },
  {
    title: "①〜④の詳細",
    content: (
      <Box>
        <Box>①の詳細</Box>
        <Box>
          上下左右のどの方向に行くかは、基本的には「最適な移動方向の求め方」にのっとって現在位置での最適な移動方向へ移動しますが、ある確率でランダムに動くようにします。そのランダムに動く確率は、ゴールにたどり着いた回数が少ない時は高く、たどり着いた回数が多くなるほど低くなるように設定します。
        </Box>
        <Box>
          確率的な動作をさせるには、「...の確率で当たった」ブロックを使います。
        </Box>
        <img
          src={new URL(
            "./2023-05-12-171830_192x55_scrot.png",
            import.meta.url
          ).toString()}
          alt="確率的なブロック"
        />
        <Box>
          引数には0~1までの数字が入り、その数字の確率で真になります。例えば0ならば常に偽、1ならば常に真、0.5ならば50%の確率で真となります。
        </Box>
        <Box>
          移動するには、「...に移動する」ブロックを使いますが、移動方向に壁がある時は移動することが出来ません。移動方向に壁があるかどうかは「...に壁がある」ブロックを使います。
        </Box>
        <img
          src={new URL(
            "./2023-05-12-174604_307x110_scrot.png",
            import.meta.url
          ).toString()}
          alt="壁があるかの判定"
        />
        <Box>②の詳細</Box>
        <Box>
          報酬は、壁がある方向へ移動しようとしたときには大きな負の報酬を、移動先がゴールだったときには大きな正の報酬を、その他の移動の場合は小さな負の報酬を与えると良いでしょう。(普通の移動の場合に負の報酬を与えるのは、最短でゴールするように学習させたいからです。)
        </Box>
        <Box>④の詳細</Box>
        <Box>
          ④が一番Q学習の肝となる部分です。評価値の更新を数式で表すと次のようになります。
        </Box>
        <img
          src={new URL(
            "./2023-05-12-233348_635x56_scrot.png",
            import.meta.url
          ).toString()}
          alt="Q値の更新式"
        />
        <Box>
          学習率は現在の評価値をどれほど更新するかの値であり、0.1くらいにすると良いです。割引率は、直近の将来の利益をどれほど重視するかを表す値であり、0.95ほどにすると良いです。
        </Box>
      </Box>
    ),
  },
];
